import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//BOOTSTRAP
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";

//sweet alert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

//Pagination
import Pagination from "v-pagination-3";

//OTP
import VOtpInput from "vue3-otp-input";

// My Event Emitter
import emitter from "./EventBus";

import {
  onHandleKeyPress,
  onHandlePaste,
  onHandleKeyUp,
  onHandleKeyDown,
} from "./api/inputText.js";

import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";

const mixins = {
  methods: {
    handleDragOver: function (event) {
      event.preventDefault();
      // Handle the drag over event here
    },
    onHandleKeyPress,
    onHandlePaste,
    onHandleKeyUp,
    onHandleKeyDown,

    success: function (message) {
      this.$swal({
        title: message,
        position: "top-end",
        icon: "success",
        toast: true,
        timer: "3000",
        showConfirmButton: false,
      });
    },
    failed: function (message) {
      this.$swal({
        title: message,
        position: "top-end",
        icon: "error",
        toast: true,
        timer: "3000",
        showConfirmButton: false,
      });
    },
    sleep: async function (ms) {
      return await new Promise((r) => {
        setTimeout(r, ms);
      });
    },
    formatDate: function (date) {
      var u = new Date(date);

      const dd = String(u.getDate()).padStart(2, "0");
      const mm = String(u.getMonth() + 1).padStart(2, "0");
      const yyyy = u.getFullYear();
      const hh = String(u.getHours()).padStart(2, "0");
      const min = String(u.getMinutes()).padStart(2, "0");
      const ss = String(u.getSeconds()).padStart(2, "0");
      return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
    },
  },
};

// 1. Assign app to a variable
let app = createApp(App);

// 2. Assign the global variable before mounting
app.config.globalProperties.$emitter = emitter; // you can use this variable with this.$john

// 3. Use router and mount app
app
  .use(router)
  .use(VueSweetalert2)
  .use(store)
  .mixin(mixins)
  .component("vue-tree", VueTree)
  .component("pagination", Pagination)
  .component("v-otp-input", VOtpInput)
  .mount("#app");
