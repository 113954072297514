<template>
  <div>
    <Header @toggle-subscribe="subscribeTvShow" v-if="$route.meta.authOnly" />

    <div class="container-fluid">
      <div class="row heightFull">
        <div
          class="col-lg-2 ps-0 pe-0 CustomSidebar"
          :class="{
            Newsidebar: showSidebar,
            'collapsed-sidebar': !showSidebar,
          }"
          id="cc-side"
        >
          <sidebar v-if="$route.meta.authOnly" />
        </div>
        <div
          class="col-md-12 col-lg-10 px-0"
          :class="{
            'col-lg-10': showSidebar,
            'col-lg-12': !showSidebar,
            'px-0': showSidebar,
          }"
        >
          <router-view />
        </div>
      </div>

      <!-- <auto-logout @auto-logout="logout"></auto-logout> -->
    </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Sidebar from "./components/Sidebar.vue";
// import Web3 from "web3";
// import exactMath from "exact-math";
import ApiClass from "./api/api";

// const MathConfig = { returnString: true, eMinus: Infinity, ePlus: Infinity };

// // COIN TRANSFER
// const Web_Gdc = new Web3("https://mainnet-rpc1.gdccoin.io/");

export default {
  name: "App",
  data() {
    return {
      showSidebar: false,
      x: null,
      wallet_addr: "",
      block_ts: 0,
    };
  },
  components: {
    Header,
    Sidebar,
    // AutoLogout
  },
  async mounted() {
    this.loginUser();
    this.startInterval_();
    // await this.getUserWallet();
    this.findwalletBalance();
  },
  watch: {
    "$store.state.WALLET_ADDRESS": async function () {
      // this.wallet_addr = this.$store.state.WALLET_ADDRESS;
      // this.startInterval_();
    },
  },
  methods: {
    // ********************** new code ******************
    async getUserWallet() {
      let response = await ApiClass.getRequest("user/get_wallet_address", true);
      if (response?.data?.status_code == 1) {
        this.wallet_addr = response?.data?.data.address;
        this.block_ts = response?.data?.data.extra;
        return;
      }
    },

    startInterval_() {
      if (this.wallet_addr == "") {
        clearInterval(this.x);
      }
      if (localStorage.getItem("token")) {
        this.x = setInterval(this.findwalletBalance, 100000, this.wallet_addr);
      }
    },

    async findwalletBalance() {
      try {
        let userAddress = this.wallet_addr;

        const usdtContractAddress = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

        let startBlock = "";

        let currentBlock =
          this.block_ts > 0 ? parseFloat(this.block_ts) + 1 : startBlock;

        if (userAddress) {
          let url = `https://apilist.tronscanapi.com/api/new/token_trc20/transfers?limit=20&start=&contract_address=${usdtContractAddress}&start_timestamp=${currentBlock}&end_timestamp=&confirm=&filterTokenValue=1&toAddress=${userAddress}`;

          let respData = await fetch(url);

          respData = await respData.json();

          if (respData && respData.total && respData.token_transfers) {
            console.log(respData && respData.total && respData.token_transfers);
            let response = await ApiClass.getRequest(
              "transaction/deposit",
              true
            );
            if (response?.data?.status_code == 1) {
              await this.getUserWallet();
              return;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    loginUser() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      if (token) {
        localStorage.setItem("token", token);
        this.$router.push("/dashboard");
      }
    },
    // async logout() {

    //********************** old code ******************** */
    subscribeTvShow(showId) {
      this.showSidebar = showId;
    },
    // startInterval_() {
    //   this.wallet_addr = this.$store.state.WALLET_ADDRESS;

    //   if (this.wallet_addr == "") {
    //     console.log("interval clear");
    //     clearInterval(this.x);
    //   }
    //   if (localStorage.getItem("token")) {
    //     this.x = setInterval(this.findwallet, 300, this.wallet_addr);
    //   }
    // },
    // async getAccountBalance(holderAddress) {
    //   let balance = await Web_Gdc.eth.getBalance(holderAddress);
    //   let token_amount = parseFloat(balance).toString();
    //   let b = exactMath.div(token_amount, 10 ** 18, MathConfig);
    //   return parseFloat(b);
    // },
    // async findwallet(wallet) {
    //   let bal = await this.getAccountBalance(wallet);

    //   if (bal > 0) {
    //     console.log(
    //       " hit check wallet => " + wallet + "  total balance => " + bal
    //     );
    //     // hit api in backend
    //     let response = await ApiClass.postRequest("transaction/deposit", true);

    //     if (response?.data?.status_code == 1) {
    //       console.log(response?.data?.message);
    //       return;
    //     }
    //   }
    // },
  },
};
</script>

<style>
body {
  background-color: var(--bg-view) !important;
}

#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
}

:root {
  --bg-view: #f6f8fe;
  --main-bg: #f7f7f7;
  --white-bg: #fff;
  --black-bg: #fff;
  --border: #d6d9df;
  --bg-1: #f2f6ff;
  --yellow-bg: #e45d1b;
  --white: #fff;
  --black: #000;
  --black-2: #2a2627;
  --grey: #888888;
  --grey2: #808080;
  --border: #dddddd;
  --green: #21bf73;
  --red: #d90429;
  --btn: #262391;
  --dark-blue: #0e4051;
  --lot-green: #35a27e;
  --lot-red: #be5150;
  --lot-bg-green: #e7f8f2;
  --lot-bg-red: #f9ebea;
  --lot-datetime: #b8bdc9;
  --text-muted: #8c9097;
  --raxios-muted: linear-gradient(
    90deg,
    rgba(85, 115, 179, 1) 95%,
    rgba(85, 115, 179, 1) 100%
  );

  --raxios-color: rgb(85, 115, 179);

  --raxios-btn-color: #f3562b;
  --blueN: #12b9e5;
  --Orange: #f9b85e;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-ExtraBold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Bold.ttf);
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Black.ttf);
  font-weight: 900;
  font-display: swap;
}

.sub-heading {
  background: #6ebbcf3b;
  border: 1px solid #12b9e524;
}
.box {
  box-shadow: rgba(1, 5, 17, 0.3) 0px 1px 3px 0px !important;
}

/**====================================
          AUTH SEC CSS START
=======================================**/

section.auth-sec {
  /* min-height: 100vh; */
  display: flex;
}

section.auth-sec .container {
  padding: 25px 20px 0px 20px;
}

.auth-image {
  text-align: center;
}

.auth-image img {
  max-width: 70%;
}

.login-heading h1 {
  color: var(--black-2);
  font-size: 24px;
  font-weight: 600;
}

.login-heading p {
  font-size: 14px;
  font-weight: 400;
  color: var(--grey);
}

.label-box label {
  font-size: 14px;
  font-weight: 600;
  color: var(--black-2);
  /* margin-bottom: 13px; */
}

:is(body .form-control, .form-select) {
  font-size: 14px;
  font-weight: 400;
  color: var(--black-2);
  /* padding: 19px 25px; */
  height: 50px;
}

body .form-control::placeholder {
  color: var(--grey2);
  font-size: 14px;
  font-weight: 400;
}

body .form-control:focus {
  background-color: var(--white);
  border-color: var(--blueN);
  box-shadow: none;
}

.submit-button .btn-primary {
  width: 100%;
}

body .btn-primary {
  background-color: var(--blueN);
  padding: 5px 15px;
  text-align: center;
  transition: 0.5s;
  color: #fff;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  border: 1px solid var(--blueN) !important;
  transition: all 0.5s ease-in-out;
}

body .btn-primary:hover {
  background-color: #fff;
  color: var(--blueN);
}

.spinner-border {
  --bs-spinner-width: 1rem !important;
  --bs-spinner-height: 1rem !important;
}

.auth-text p {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: var(--grey2);
  letter-spacing: 0.8px;
}

.error_msg {
  color: red;
  font-size: 10px;
  font-weight: 500;
}

/**================ OTP INPUT FIELD CSS START ================ **/

.otp-input div {
  justify-content: space-between;
  width: 100%;
}

input.otp-input {
  min-height: 20px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid var(--grey-2);
  font-weight: 500;
  background-color: var(--white-bg);
  width: 40px;
  box-shadow: -1px 0px 3px rgb(0 0 0 / 25%);
  padding: 10px;
  margin-left: 3px;
  border: 1px solid #adadad;
}

input.otp-input:focus-visible {
  outline: none;
}

.mb-33 {
  margin-bottom: 33px;
}

/* auth pages */
.heightFull {
  height: 100vh;
}

.ContainerNew::before {
  content: "";
  background: var(--blueN);
  width: 50%;
  height: 100vh;
  display: inline-block;
  position: absolute;
  left: 0;
  z-index: -1;
}

.form-Wrapper {
  box-shadow: -1px 0px 4px rgb(0 0 0 / 25%);
  margin: auto;
}

.auth-text a,
.textNew a {
  color: var(--blueN);
}

.auth-text a:hover {
  text-decoration: none !important;
}

body .form-check-input:checked {
  background-color: var(--blueN);
  border-color: var(--blueN);
}

@media screen and (max-width: 767px) {
  .ContainerNew {
    padding: 0 20px;
  }

  .ContainerNew::before {
    width: 100%;
  }
}

/* Sidebar code */
.CustomSidebar.collapsed-sidebar {
  display: none;
}

.CustomSidebar .router-link-active {
  background-color: var(--blueN);
  color: #fff !important;
}

.CustomSidebar .router-link-active img {
  filter: invert(100%) sepia(99%) saturate(2%) hue-rotate(12deg)
    brightness(112%) contrast(101%);
}

.cardTrade {
  padding: 24px 35px;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  border: 0rem solid transparent;
  border-radius: 1.25rem;
  /* box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05); */
  box-shadow: 0px 10px 20px rgba(55, 55, 89, 0.08);
}

.coinType span {
  width: 40px;
  height: 40px;
}

.greenBg span {
  background-color: #32a9544d;
}

.redBg span {
  background-color: rgb(216 24 24 / 15%);
}

:is(.currency-text h5, .currency-text span) {
  font-size: 14px;
  color: var(--black);
  font-weight: 500;
}

.textGrey {
  color: #adadad !important;
}

.cursorPointer {
  cursor: pointer;
}

/* ===========PAGINATION CSS START============== */

.pagination {
  justify-content: end !important;
  background-color: transparent !important;
}

.page-link {
  z-index: 3 !important;
  color: var(--lot-green) !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 2px;
  border-color: var(--border) !important;
  font-size: var(--fs-15) !important;
}

.page-item.active .page-link {
  z-index: 3 !important;
  color: var(--white) !important;
  box-shadow: none !important;
  background-color: var(--blueN) !important;
  border-color: var(--blueN) !important;
}

.pagination_box ul li {
  border: none !important;
  margin-bottom: 20px !important;
  margin-bottom: 0 !important;
  color: var(--lot-green);
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  box-shadow: none !important;
}

.VuePagination__pagination {
  /* box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px !important; */
  background-color: var(--yellow-bg);
  color: var(--yellow-bg);
  gap: 4px;
}

.VuePagination nav p {
  color: #808080;
  font-size: var(--fs-14);
}

/* ===========PAGINATION CSS FINISH============== */
.img_log {
  border: 2px solid #323232;
  max-width: 120px;
  padding: 32px 0;
  border-radius: 50%;
}

.input-group input + span {
  position: absolute;
  right: 3px;
  top: 7px;
  z-index: 10;
  background-color: #0000;
  border: 0;
}

@media screen and (max-width: 767px) {
  .refer-link {
    width: 100% !important;
  }

  .cardTrade {
    padding: 20px;
  }

  .level__income th {
    white-space: pre;
  }

  .left-side {
    width: 100%;
  }

  .logo img {
    width: 170px;
  }

  body .pagination_box nav {
    flex-direction: column-reverse;
    gap: 12px;
  }
}

.security-form {
  max-width: 690px;
  margin: auto;
}

.pagination_box .VuePagination {
  width: 100%;
}

.pagination_box nav {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.pagination_box nav ul,
.VuePagination nav p {
  margin: 0;
}

body .form-Wrapper {
  max-width: 500px !important;
  margin: auto;
}

input.otp-input {
  max-width: 60px;
  width: 99%;
  height: 60px;
  margin: 0;
  border-color: var(--white);
}

input.otp-input:focus {
  border-color: var(--blueN) !important;
}

.tree-container {
  width: 100% !important;
  /* border: 1px solid var(--active) !important; */
  overflow: scroll !important;
  direction: ltr;
}
</style>
